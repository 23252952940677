import { useState, useRef } from "react";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import SaveIcon from "@mui/icons-material/Save";
import VehicleQuoteForm from "./VehicleQuoteForm";
import WrkCmpQuoteForm from "./WrkCmpQuoteForm";
import PublicLiabilityQuoteForm from "./PublicLiabilityQuoteForm";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  makeSelectQuoteData,
  selectLoadingQuoteData,
  selectLoadingLastModifiedDates,
} from "../../../lib/store/features/quoteData.slice";
import { enqueue as showNotif } from "../../../lib/store/features/notifications.slice";
import type {
  QuoteType,
  QuoteFormState,
  QuoteStatus,
  WrkCmpQuoteFormState,
  PublicLiabilityQuoteFormState,
} from "../../../lib/types";
import {
  QUOTE_INFO as QIN,
  VEHICLE_QUOTE_TYPES,
  WRK_CMP_QUOTE_TYPES,
  PL_QUOTE_TYPES,
} from "../../../lib/constants";

interface Props {
  open: boolean;
  quoteType: QuoteType;
  selectedProductData?:
    | QuoteFormState
    | WrkCmpQuoteFormState
    | PublicLiabilityQuoteFormState;
  quoteStatus: QuoteStatus;
  onRemove?: () => void;
  onClose: () => void;
  onSubmit: (data: QuoteFormState | WrkCmpQuoteFormState) => Promise<boolean>;
}

function FormDialog({
  quoteType,
  open,
  selectedProductData,
  quoteStatus,
  onRemove,
  onClose,
  onSubmit,
}: Props) {
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useAppDispatch();
  const formRef = useRef(null);
  const quoteData = useAppSelector(makeSelectQuoteData(quoteType));
  const loadingQuoteData = useAppSelector(selectLoadingQuoteData);
  const loadingModifiedDates = useAppSelector(selectLoadingLastModifiedDates);

  const quoteProps = QIN[quoteType];

  const withNamedDriverDiscount = ["business", "private"];

  const handleSubmit = async () => {
    setIsLoading(true);

    if ((formRef.current as any)?.dumpData) {
      const data: QuoteFormState | WrkCmpQuoteFormState = (
        formRef.current as any
      ).dumpData();
      // If workers compensation or public liability, check that a valid occupation has been selected
      if (isWrkComQuote || isPubLiabQuote) {
        if (
          !(data as WrkCmpQuoteFormState).selectedOccupation ||
          !(data as WrkCmpQuoteFormState)?.selectedOccupation?.rate
        ) {
          dispatch(
            showNotif({
              message: "Please select a valid occupation",
              options: { variant: "error" },
            })
          );
          setIsLoading(false);
          return;
        }
      }

      await onSubmit(data);
    }

    setIsLoading(false);
  };

  if (!quoteType) return null;

  const isVehicleQuote = VEHICLE_QUOTE_TYPES.includes(quoteType);
  const isWrkComQuote = WRK_CMP_QUOTE_TYPES.includes(quoteType);
  const isPubLiabQuote = PL_QUOTE_TYPES.includes(quoteType);

  return (
    <Dialog open={open} fullWidth maxWidth="md">
      <DialogContent sx={{ p: 0 }}>
        {isVehicleQuote && (
          <VehicleQuoteForm
            {...quoteProps}
            ref={formRef}
            withNamedDriverDiscount={withNamedDriverDiscount.includes(
              quoteType
            )}
            quoteData={quoteData}
            existingData={selectedProductData as QuoteFormState}
            isLoadingData={loadingQuoteData}
            isLoadingLastModifiedDates={loadingModifiedDates}
          />
        )}
        {isWrkComQuote && (
          <WrkCmpQuoteForm
            ref={formRef}
            {...quoteProps}
            existingData={selectedProductData as WrkCmpQuoteFormState}
          />
        )}
        {isPubLiabQuote && (
          <PublicLiabilityQuoteForm
            ref={formRef}
            {...quoteProps}
            existingData={selectedProductData as PublicLiabilityQuoteFormState}
          />
        )}
      </DialogContent>
      <DialogActions sx={{}}>
        {!!selectedProductData && (
          <>
            <Button
              variant="outlined"
              color="error"
              onClick={onRemove}
              disabled={quoteStatus !== "pending"}
            >
              Remove Product
            </Button>
            <div style={{ flex: "1 0 0" }} />
          </>
        )}

        <Button onClick={onClose}>Cancel</Button>
        <LoadingButton
          loading={isLoading}
          disabled={
            quoteStatus !== "pending" ||
            loadingModifiedDates ||
            loadingQuoteData
          }
          loadingPosition="end"
          endIcon={<SaveIcon />}
          variant="contained"
          onClick={handleSubmit}
        >
          {!!selectedProductData ? "Update Product" : "Add Product"}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

export default FormDialog;

import React from "react";
import { Page } from "@react-pdf/renderer";
import VehiclePolicy from "./VehiclePolicy";
import WrkCmpPolicy from "./WrkCmpPolicy";
import PubLiabPolicy from "./PubLiabPolicy";
import VehicleTotals from "./VehicleTotals";
import WrkCmpTotals from "./WrkCmpTotals";
import PubLiabTotals from "./PubLiabTotals";
import CombinedTotals from "./CombinedTotals";
import { PageHeader, PageFooter } from "../Page";
import type {
  PDFClientDetails,
  PDFQuoteDetails,
  PDFAgentDetails,
  CalculationsType,
  PDFQuoteVehicleDetails,
  PDFQuoteWrkCmpDetails,
  PDFQuotePubLiabDetails,
} from "../../../../../lib/types";
import {
  WRK_CMP_QUOTE_TYPES,
  VEHICLE_QUOTE_TYPES,
  PL_QUOTE_TYPES,
} from "../../../../../lib/constants";
import styles from "../styles";

interface Props {
  clientDetails: PDFClientDetails;
  quoteDetails: PDFQuoteDetails;
  agentDetails: PDFAgentDetails;
}

function Quote({ clientDetails, quoteDetails, agentDetails }: Props) {
  let pageNumber = 1;

  return (
    <>
      {quoteDetails.products
        .filter((product) => VEHICLE_QUOTE_TYPES.includes(product.quoteType))
        .map((product, index, products) => {
          const qtProd = (
            <React.Fragment key={index}>
              {/* Policy Page */}
              <Page size="A4" style={styles.page}>
                <PageHeader {...agentDetails} />
                <VehiclePolicy
                  clientDetails={clientDetails}
                  quoteDetails={{
                    title: product.title,
                    withNamedDriverDiscount: (product as PDFQuoteVehicleDetails)
                      .withNamedDriverDiscount,
                    insuranceValues: (product as PDFQuoteVehicleDetails)
                      .insuranceValues,
                    vehicle: (product as PDFQuoteVehicleDetails).vehicle,
                  }}
                />
                <PageFooter
                  pageNumber={pageNumber}
                  brokerageName={agentDetails.brokerageName}
                />
              </Page>

              {/* Calculations Page */}
              <Page
                size="A4"
                style={styles.page}
                orientation={
                  (product as PDFQuoteVehicleDetails).yearsRequired > 2
                    ? "landscape"
                    : "portrait"
                }
              >
                <PageHeader {...agentDetails} />
                <VehicleTotals
                  quoteDetails={{
                    yearsRequired: (product as PDFQuoteVehicleDetails)
                      .yearsRequired,
                    calculations: (product as PDFQuoteVehicleDetails)
                      .calculations as CalculationsType,
                    calculationFunctions: (product as PDFQuoteVehicleDetails)
                      .calculationFunctions,
                    minPremium: (product as PDFQuoteVehicleDetails).minPremium,
                    taxes: (product as PDFQuoteVehicleDetails).taxes,
                    vehicle: (product as PDFQuoteVehicleDetails).vehicle,
                  }}
                  multipleProducts={products.length > 1}
                />
                <PageFooter
                  pageNumber={pageNumber + 1}
                  brokerageName={agentDetails.brokerageName}
                />
              </Page>
            </React.Fragment>
          );

          pageNumber += 2;

          return qtProd;
        })}

      {quoteDetails.products
        .filter((product) => WRK_CMP_QUOTE_TYPES.includes(product.quoteType))
        .map((product, index) => {
          const qtProd = (
            <React.Fragment key={index}>
              {/* Policy Page */}
              <Page size="A4" style={styles.page}>
                <PageHeader {...agentDetails} />

                <WrkCmpPolicy
                  clientDetails={clientDetails}
                  quoteDetails={{
                    title: product.title,
                    category: (product as PDFQuoteWrkCmpDetails).category,
                    occupation: (product as PDFQuoteWrkCmpDetails).occupation,
                    wageRoll: (product as PDFQuoteWrkCmpDetails).wageRoll,
                    rate: (product as PDFQuoteWrkCmpDetails).rate,
                    claimsHistory: (product as PDFQuoteWrkCmpDetails)
                      .claimsHistory,
                    hausKraiOption: (product as PDFQuoteWrkCmpDetails)
                      .hausKraiOption,
                    hausKraiAmount: (product as PDFQuoteWrkCmpDetails)
                      .hausKraiAmount,
                    wethaOption: (product as PDFQuoteWrkCmpDetails).wethaOption,
                    wethaAmount: (product as PDFQuoteWrkCmpDetails).wethaAmount,
                    bekimPeiOption: (product as PDFQuoteWrkCmpDetails)
                      .bekimPeiOption,
                    bekimPeiAmount: (product as PDFQuoteWrkCmpDetails)
                      .bekimPeiAmount,
                    commonLawOption: (product as PDFQuoteWrkCmpDetails)
                      .commonLawOption,
                    commonLawAmount: (product as PDFQuoteWrkCmpDetails)
                      .commonLawAmount,
                  }}
                />
                <PageFooter
                  pageNumber={pageNumber}
                  brokerageName={agentDetails.brokerageName}
                />
              </Page>

              {/* Calculations Page */}
              <Page size="A4" style={styles.page} orientation="portrait">
                <PageHeader {...agentDetails} />
                <WrkCmpTotals
                  quoteDetails={{
                    minPremium: (product as PDFQuoteWrkCmpDetails).minPremium,
                    sumInsuredPremium: (product as PDFQuoteWrkCmpDetails)
                      .sumInsuredPremium,
                    optionalExtras: (product as PDFQuoteWrkCmpDetails)
                      .optionalExtras,
                    subTotal: (product as PDFQuoteWrkCmpDetails).subTotal,
                    gst: (product as PDFQuoteWrkCmpDetails).gst,
                    wcl: (product as PDFQuoteWrkCmpDetails).wcl,
                  }}
                />
                <PageFooter
                  pageNumber={pageNumber + 1}
                  brokerageName={agentDetails.brokerageName}
                />
              </Page>
            </React.Fragment>
          );

          pageNumber += 2;

          return qtProd;
        })}

      {quoteDetails.products
        .filter((product) => PL_QUOTE_TYPES.includes(product.quoteType))
        .map((product, index) => {
          const qtProd = (
            <React.Fragment key={index}>
              {/* Policy Page */}
              <Page size="A4" style={styles.page}>
                <PageHeader {...agentDetails} />
                <PubLiabPolicy
                  clientDetails={clientDetails}
                  quoteDetails={{
                    title: product.title,
                    category: (product as PDFQuotePubLiabDetails).category,
                    occupation: (product as PDFQuotePubLiabDetails).occupation,
                    turnover: (product as PDFQuotePubLiabDetails).turnover,
                    rate: (product as PDFQuotePubLiabDetails).rate,
                    claimsHistory: (product as PDFQuotePubLiabDetails)
                      .claimsHistory,
                    selectedLOLValues: (product as PDFQuotePubLiabDetails)
                      .selectedLOLValues,
                    selectedLOLAmounts: (product as PDFQuotePubLiabDetails)
                      .selectedLOLAmounts,
                  }}
                />
                <PageFooter
                  pageNumber={pageNumber}
                  brokerageName={agentDetails.brokerageName}
                />
              </Page>

              {/* Calculations Page */}
              <Page size="A4" style={styles.page} orientation="portrait">
                <PageHeader {...agentDetails} />
                <PubLiabTotals
                  quoteDetails={{
                    lolPremium: (product as PDFQuotePubLiabDetails).lolPremium,
                    gst: (product as PDFQuotePubLiabDetails).gst,
                    icl: (product as PDFQuotePubLiabDetails).icl,
                  }}
                />
                <PageFooter
                  pageNumber={pageNumber + 1}
                  brokerageName={agentDetails.brokerageName}
                />
              </Page>
            </React.Fragment>
          );

          pageNumber += 2;

          return qtProd;
        })}

      {/* Combined Totals Page */}
      {quoteDetails.products.length > 1 && (
        <Page size="A4" style={styles.page} orientation="portrait">
          <PageHeader {...agentDetails} />
          <CombinedTotals quoteDetails={quoteDetails} />
          <PageFooter
            pageNumber={pageNumber}
            brokerageName={agentDetails.brokerageName}
          />
        </Page>
      )}
    </>
  );
}

export default Quote;

import * as React from "react";
import { Formik, Field, Form, FieldProps } from "formik";
import Avatar from "@mui/material/Avatar";
import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";
import LoginIcon from "@mui/icons-material/Login";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Copyright from "../Copyright";
import { validationSchema } from "./validationSchema";
import bgImage from "../../_assets/signin-bg.jpg";

interface Props {
  appVersion: string;
  onSubmit: (email: string, password: string) => Promise<boolean>;
}

export default function SignIn({ onSubmit, appVersion }: Props) {
  const [isLoading, setIsLoading] = React.useState(false);

  const fields: { [key: string]: string } = {
    email: "",
    password: "",
  };

  const inputProps: { [key: string]: any } = {
    email: {
      label: "Email address",
      placeholder: "Enter your email address",
      type: "email",
      autoComplete: "email",
      autoFocus: true,
      Component: TextField,
    },
    password: {
      label: "Password",
      type: "password",
      placeholder: "Enter your password",
      autoComplete: "current-password",
      Component: TextField,
    },
  };

  return (
    <Formik
      initialValues={{ ...fields }}
      validationSchema={validationSchema}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        setIsLoading(true);
        const loginResult = await onSubmit(values.email, values.password);
        setSubmitting(false);
        setIsLoading(false);
        if (loginResult) resetForm();
      }}
    >
      <Form noValidate>
        <div
          style={{
            minHeight: "100vh",
            backgroundImage: `url(${bgImage})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Container
            component="main"
            maxWidth="xs"
            sx={{ pt: 2, backgroundColor: "rgba(255, 255, 255, 0.95)" }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                Quote Dashboard
              </Typography>
              <Box sx={{ mt: 1 }}>
                {Object.keys(fields).map((fieldName) => {
                  const { Component, type, name, ...props } =
                    inputProps[fieldName];

                  return (
                    <Field type={type} name={fieldName} key={fieldName}>
                      {({ field, meta, form }: FieldProps) => {
                        return (
                          <Component
                            {...field}
                            {...props}
                            type={type}
                            margin="normal"
                            required
                            fullWidth
                            error={meta.touched && !!meta.error}
                            helperText={
                              meta.touched && !!meta.error ? meta.error : null
                            }
                          />
                        );
                      }}
                    </Field>
                  );
                })}

                <LoadingButton
                  loading={isLoading}
                  loadingPosition="end"
                  endIcon={<LoginIcon />}
                  variant="contained"
                  fullWidth
                  type="submit"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Sign In
                </LoadingButton>
              </Box>
            </Box>
            <Copyright sx={{ mt: 3, pb: 1 }} />
            <Typography
              component="div"
              variant="caption"
              color="text.secondary"
              sx={{ textAlign: "center", pb: 2 }}
            >
              v{appVersion}
            </Typography>
          </Container>
        </div>
      </Form>
    </Formik>
  );
}

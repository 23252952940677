import { Text, View } from "@react-pdf/renderer";
import { useNumberFormatter } from "../../../../../hooks";
import { getTotalsCalculations } from "../../../../../lib/helpers";
import type {
  CalculationsType,
  PDFQuoteDetails,
  PDFQuotePubLiabDetails,
  PDFQuoteVehicleDetails,
  PDFQuoteWrkCmpDetails,
} from "../../../../../lib/types";
import {
  PL_QUOTE_TYPES,
  VEHICLE_QUOTE_TYPES,
  WRK_CMP_QUOTE_TYPES,
} from "../../../../../lib/constants";
import styles from "../styles";

interface Props {
  quoteDetails: PDFQuoteDetails;
}

function QuoteCombinedTotals({ quoteDetails }: Props) {
  const moneyFormatter = useNumberFormatter();

  let combinedTotal = 0;

  const vehicleValues = quoteDetails.products
    .filter((product) => VEHICLE_QUOTE_TYPES.includes(product.quoteType))
    .map((product, index) => {
      const {
        yearsRequired,
        calculations,
        calculationFunctions,
        minPremium,
        taxes,
        vehicle,
        title,
      } = product as PDFQuoteVehicleDetails;
      const { sumInsured, sumInsuredPremium, discountPercentage } =
        calculations as CalculationsType;
      const { grandTotal } = getTotalsCalculations({
        sumInsuredPremium,
        yearsRequired,
        sumInsured,
        calculationFunctions,
        discountPercentage,
        minPremium,
        taxes,
      });

      combinedTotal += grandTotal;

      return {
        id: `${title} Policy ${index}`,
        title: `${title} Policy`,
        subtitle: `${vehicle.vehicleYear} ${vehicle.vehicleMake} ${vehicle.vehicleModel}`,
        value: grandTotal,
      };
    });

  const wrkCmpValues = quoteDetails.products
    .filter((product) => WRK_CMP_QUOTE_TYPES.includes(product.quoteType))
    .map((product, index) => {
      const { sumInsuredPremium, optionalExtras, gst, wcl } =
        product as PDFQuoteWrkCmpDetails;
      const grandTotal = sumInsuredPremium + optionalExtras + gst + wcl;

      combinedTotal += grandTotal;

      return {
        id: `Workers Compensation Policy ${index}`,
        title: `Workers Compensation Policy`,
        value: grandTotal,
      };
    });

  const pubLiabValues = quoteDetails.products
    .filter((product) => PL_QUOTE_TYPES.includes(product.quoteType))
    .map((product, index) => {
      const { lolPremium, gst, icl } = product as PDFQuotePubLiabDetails;
      const grandTotal = lolPremium + gst + icl;

      combinedTotal += grandTotal;

      return {
        id: `Public Liabilty Policy ${index}`,
        title: `Public Liabilty Policy`,
        value: grandTotal,
      };
    });

  const fields = [
    ...vehicleValues,
    ...wrkCmpValues,
    ...pubLiabValues,
    {
      id: "Grand Total",
      title: "Grand Total",
      value: combinedTotal,
      isTotal: true,
    },
  ];

  return (
    <>
      {/* Quotation Totals */}
      <View style={[styles.subSectionHeadingContainer, styles.marginBottom8]}>
        <Text style={[styles.textNormal, styles.textBlue]}>
          Total Quotation
        </Text>
        <View style={styles.subSectionHeaderLine} />
      </View>

      <View>
        {fields.map(({ id, ...row }) => (
          <View key={id} style={[styles.flexRow, styles.trBorderBottom]}>
            <View style={[styles.flex1, styles.padding8, styles.flexColumn]}>
              <Text
                style={(row as any).isTotal ? styles.thText : styles.tdText}
              >
                {(row as any).title}
              </Text>
              {(row as any).subtitle && (
                <View>
                  <View style={{ paddingLeft: 1 }} />
                  <Text
                    style={(row as any).isTotal ? styles.thText : styles.tdText}
                  >
                    {(row as any).subtitle}
                  </Text>
                </View>
              )}
            </View>

            <View
              style={[
                styles.flex1,
                styles.justifyEnd,
                styles.flexRow,
                styles.paddingTop8,
                styles.paddingBottom8,
              ]}
            >
              <Text
                style={(row as any).isTotal ? styles.thText : styles.tdText}
              >
                {moneyFormatter.format(row.value)}
              </Text>
            </View>
          </View>
        ))}
      </View>
    </>
  );
}

QuoteCombinedTotals.defaultProps = {
  yearsRequired: 1,
};

export default QuoteCombinedTotals;

import { parse, addDays } from "date-fns";
import { useAppSelector } from "./redux-hooks";
import type {
  PDFQuotePubLiabDetails,
  PublicLiabilityQuoteFormState,
  WrkCmpQuoteFormState,
} from "../lib/types";
import { selectClientQuote } from "../lib/store/features/quotes.slice";
import { selectMe } from "../lib/store/features/user.slice";
import { makeSelectAllQuoteData } from "../lib/store/features/quoteData.slice";
import {
  calculatePremium,
  calculateTppdLimitIncreaseAmount,
} from "../lib/helpers";
import {
  QUOTE_INFO,
  WRK_CMP_QUOTE_TYPES,
  PL_QUOTE_TYPES,
} from "../lib/constants";
import type {
  QuoteFormData,
  QuoteFormState,
  QuoteType,
  PDFQuoteVehicleDetails,
  PDFQuoteWrkCmpDetails,
} from "../lib/types";
import { selectWrkCmpOptionalExtras } from "../lib/store/features/quoteData.slice";

export function usePdfQuoteDetails() {
  const wrkCmpOptionalExtras = useAppSelector(selectWrkCmpOptionalExtras);
  const clientQuote = useAppSelector(selectClientQuote);

  const me = useAppSelector(selectMe);
  const allQuoteData = useAppSelector(makeSelectAllQuoteData());

  const calculatePremiumValue =
    (quoteType: QuoteType) => (sumInsured: number) => {
      if (!allQuoteData[quoteType]) return 0;

      return calculatePremium({
        sumInsured,
        quoteData: allQuoteData[quoteType] as QuoteFormData,
      });
    };

  const calculateTppdLimitIncreaseValue =
    (
      quoteType: QuoteType,
      insuranceValues: QuoteFormState["insuranceValues"]
    ) =>
    (premium: number) => {
      if (!allQuoteData[quoteType]) return 0;

      return calculateTppdLimitIncreaseAmount({
        quoteData: allQuoteData[quoteType] as QuoteFormData,
        premium,
        insuranceValues,
      });
    };

  const withNamedDriverDiscount = ["business", "private"];

  const clientDetails = {
    isPrivate: clientQuote.client.isPrivate,
    clientTitle: clientQuote.client.clientTitle,
    clientFirstName: clientQuote.client.clientFirstName,
    clientLastName: clientQuote.client.clientLastName,
    clientAddress: clientQuote.client.clientAddress,
  };
  const agentDetails = {
    agentName: me?.name ?? "",
    agentPhone: me?.phone ?? "",
    agentEmail: me?.email ?? "",
    brokerageName: me?.brokerage?.name ?? "",
    brokerageAddressOne: me?.brokerage?.address_line_one ?? "",
    brokerageAddressTwo: me?.brokerage?.address_line_two ?? "",
    brokerageEmail: me?.brokerage?.email ?? "",
    brokeragePhone: me?.brokerage?.phone ?? "",
    brokerageLogo: me?.brokerage?.logo ?? "",
  };
  const quoteDetails = {
    fileName: `${clientQuote.quoteReference || "quote"}.pdf`,
    reference: clientQuote.quoteReference,
    dates: {
      quoteDate: clientQuote.effectiveDate
        ? parse(clientQuote.effectiveDate, "yyyy-MM-dd", new Date())
        : new Date(),
      expiryDate: clientQuote.effectiveDate
        ? addDays(
            parse(clientQuote.effectiveDate, "yyyy-MM-dd", new Date()),
            30
          )
        : new Date(),
      acceptedDate: clientQuote.acceptedDate
        ? parse(clientQuote.acceptedDate, "yyyy-MM-dd", new Date())
        : null,
    },
    products: clientQuote.products.map(({ data, quoteType }) => {
      if (WRK_CMP_QUOTE_TYPES.includes(quoteType)) {
        const {
          selectedCategory,
          selectedOccupation,
          wageRoll,
          claimsHistory,
          calculations: calc,
          taxes: txs,
          selectedOptionalExtraAmounts: soea,
          selectedOptionalExtras: soe,
        } = data as WrkCmpQuoteFormState;
        return {
          title: QUOTE_INFO[quoteType].quoteTitle,
          quoteType,
          category: selectedCategory?.category_name ?? "",
          occupation: selectedOccupation?.occupation_name ?? "",
          wageRoll,
          rate: selectedOccupation?.rate ?? 0,
          claimsHistory: claimsHistory,
          hausKraiOption:
            wrkCmpOptionalExtras?.["Haus Krai"]?.data[soe["Haus Krai"]]
              ?.option ?? "",
          hausKraiAmount: soea["Haus Krai"],
          wethaOption:
            wrkCmpOptionalExtras?.["WETHA"]?.data[soe["WETHA"]]?.option ?? "",
          wethaAmount: soea.WETHA,
          bekimPeiOption:
            wrkCmpOptionalExtras?.["BEKIM PEI"]?.data[soe["BEKIM PEI"]]
              ?.option ?? "",
          bekimPeiAmount: soea["BEKIM PEI"],
          commonLawOption:
            wrkCmpOptionalExtras?.["Common Law"]?.data[soe["Common Law"]]
              ?.option ?? "",
          commonLawAmount: soea["Common Law"],
          minPremium:
            allQuoteData?.workers_compensation?.minimumPremium?.premium ?? 0,
          sumInsuredPremium: calc.sumInsuredPremium,
          optionalExtras: calc.optionalExtras,
          subTotal: calc.subTotal,
          gst: txs.gst,
          wcl: txs.wcl,
        } as PDFQuoteWrkCmpDetails;
      } else if (PL_QUOTE_TYPES.includes(quoteType)) {
        const {
          selectedCategory,
          selectedOccupation,
          turnover,
          claimsHistory,
          selectedLOLValues,
          selectedLOLAmounts,
          taxes,
          lolPremiumAmount,
        } = data as PublicLiabilityQuoteFormState;

        return {
          title: QUOTE_INFO[quoteType].quoteTitle,
          quoteType,
          category: selectedCategory?.category_name ?? "",
          occupation: selectedOccupation?.occupation_name ?? "",
          turnover,
          rate: selectedOccupation?.rate ?? 0,
          claimsHistory: claimsHistory,
          selectedLOLValues,
          selectedLOLAmounts,
          lolPremium: lolPremiumAmount,
          gst: taxes.gst,
          icl: taxes.icl,
        } as PDFQuotePubLiabDetails;
      } else {
        const {
          clientVehicleValues,
          calculations,
          insuranceValues,
          quoteYears,
        } = data as QuoteFormState;

        return {
          title: QUOTE_INFO[quoteType].quoteTitle,
          vehicle: {
            ...clientVehicleValues,
          },
          quoteType,
          withNamedDriverDiscount: withNamedDriverDiscount.includes(quoteType),
          insuranceValues,
          yearsRequired: quoteYears,
          calculations,
          calculationFunctions: {
            calculatePremium: calculatePremiumValue(quoteType),
            calculateExtras: calculateTppdLimitIncreaseValue(
              quoteType,
              insuranceValues
            ),
          },
          taxes: allQuoteData[quoteType]?.taxes || {
            gst: 0,
            icl: 0,
          },
          minPremium: allQuoteData[quoteType]?.minimumPremium.premium || 0,
        } as PDFQuoteVehicleDetails;
      }
    }),
  };

  return {
    clientDetails,
    agentDetails,
    quoteDetails,
  };
}

import { useAppSelector } from "../../hooks";
import RefreshQuoteData from "./RefreshQuoteData";
import InitQuoteDataOptionalExtras from "./InitQuoteDataOptionalExtras";
import { selectMe } from "../../lib/store/features/user.slice";

function Init() {
  const me = useAppSelector(selectMe);

  return (
    <>
      {!!me && <RefreshQuoteData />}
      {!!me && <InitQuoteDataOptionalExtras />}
    </>
  );
}

export default Init;

import { request } from "../index";
import { apiUrls } from "../api-urls";
import type { QuoteDataFormValuesSave, QuoteType } from "../../types";

const urls = apiUrls.quoteData;

export function fetchPublicLiabilityCategories() {
  return request({
    url: urls.publicLiabilityCategories,
    method: "GET",
  });
}

export function fetchPublicLiabilityCategoryOccupations($category_id: number) {
  return request({
    url: urls.publicLiabilityOccupations.replace(
      /:CATEGORY_ID/,
      $category_id.toString()
    ),
    method: "GET",
  });
}

export function fetchWrkCmpCategories() {
  return request({
    url: urls.wrkCmpCategories,
    method: "GET",
  });
}

export function fetchWrkCmpCategoryOccupations($category_id: number) {
  return request({
    url: urls.wrkCmpOccupations.replace(
      /:CATEGORY_ID/,
      $category_id.toString()
    ),
    method: "GET",
  });
}

export function fetchOptionalExtras() {
  return request({
    url: urls.optionalExtras,
    method: "GET",
  });
}

export function fetchQuoteTypes() {
  return request({
    url: urls.quoteTypes,
    method: "GET",
  });
}

export function fetchQuoteFeatures() {
  return request({
    url: urls.quoteFeatures,
    method: "GET",
  });
}

export function fetchLastModified({
  quoteType,
}: {
  quoteType: QuoteType | "all";
}) {
  return request({
    url: urls.lastModified.replace(/:QUOTE_TYPE/, quoteType),
    method: "GET",
  });
}

export function fetchQuoteData({ quoteType }: { quoteType: QuoteType }) {
  return request({
    url: urls.data.replace(/:QUOTE_TYPE/, quoteType),
    method: "GET",
  });
}

export function saveQuoteData({
  quoteType,
  quoteData,
}: {
  quoteType: QuoteType;
  quoteData: QuoteDataFormValuesSave;
}) {
  return request({
    url: urls.data.replace(/:QUOTE_TYPE/, quoteType),
    method: "PUT",
    data: { quoteData },
  });
}

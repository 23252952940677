import { combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import logger from "redux-logger";
import storage from "redux-persist/lib/storage";

/* Slices */
import brokeragesReducer from "./features/brokerages.slice";
import dashboardReducer from "./features/dashboard.slice";
import dialogsReducer from "./features/dialogs.slice";
import notificationsReducer from "./features/notifications.slice";
import quoteDataReducer from "./features/quoteData.slice";
import quotesReducer from "./features/quotes.slice";
import quoteReportsReducer from "./features/quoteReports.slice";
import quotesPaginationReducer from "./features/quotesPagination.slice";
import settingsAppReducer from "./features/settings-app.slice";
import settingsEmailReducer from "./features/settings-email.slice";
import userReducer from "./features/user.slice";
import usersReducer from "./features/users.slice";

const persistConfig = {
  key: "tpal-quotes",
  version: 1,
  storage,
  whitelist: ["user"],
};

const appReducer = combineReducers({
  brokerages: brokeragesReducer,
  dashboard: dashboardReducer,
  dialogs: dialogsReducer,
  notifications: notificationsReducer,
  quotes: quotesReducer,
  quoteData: quoteDataReducer,
  quotesPagination: quotesPaginationReducer,
  quoteReports: quoteReportsReducer,
  settingsApp: settingsAppReducer,
  settingsEmail: settingsEmailReducer,
  user: userReducer,
  users: usersReducer,
});

const rootReducer = (state: any, action: any) => {
  if (action.type === "user/logout") {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMidleWare) => {
    const opts = {
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    };

    if (process.env.NODE_ENV === "development") {
      return getDefaultMidleWare(opts).concat(logger);
    }
    return getDefaultMidleWare(opts);
  },
});

export const persistor = persistStore(store);

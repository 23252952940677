import { useEffect } from "react";
import Paper from "@mui/material/Paper";
import Container from "@mui/material/Container";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  getLastModifiedDates,
  makeSelectQuoteData,
  makeSelectLastModifiedDateByType,
  selectLoadingQuoteData,
  selectLoadingLastModifiedDates,
  selectSavingQuoteData,
  saveQuoteData,
} from "../../lib/store/features/quoteData.slice";
import { enqueue as showNotif } from "../../lib/store/features/notifications.slice";
import Typography from "@mui/material/Typography";
import QuoteData from "../../components/QuoteData";
import { QuoteType, QuoteDataFormValues } from "../../lib/types";
import {
  VALID_QUOTE_TYPES as VQT,
  QUOTE_DATA_INFO as QDI,
} from "../../lib/constants";

function Data() {
  const params = useParams();
  const dispatch = useAppDispatch();
  const lastModifiedDate = useAppSelector(
    makeSelectLastModifiedDateByType(params.quoteType as QuoteType)
  );
  const data = useAppSelector(
    makeSelectQuoteData(params.quoteType as QuoteType)
  );
  const savingQuoteData = useAppSelector(selectSavingQuoteData);
  const loadingQuoteData = useAppSelector(selectLoadingQuoteData);
  const loadingModifiedDates = useAppSelector(selectLoadingLastModifiedDates);

  useEffect(() => {
    if (params.quoteType && VQT.includes(params.quoteType as QuoteType)) {
      dispatch(
        getLastModifiedDates({ quoteType: params.quoteType as QuoteType })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  const handleSave = async (formData: QuoteDataFormValues) => {
    try {
      if (!params.quoteType || !VQT.includes(params.quoteType as QuoteType)) {
        return;
      }

      const { taxesICL, taxesGST, ...restFormData } = formData;

      const result = await dispatch(
        saveQuoteData({
          quoteType: params.quoteType as QuoteType,
          quoteData: {
            ...restFormData,
            taxes: {
              icl: parseFloat(String(taxesICL)),
              gst: parseFloat(String(taxesGST)),
            },
          },
        })
      );

      if ((result as any).error) {
        throw new Error((result as any).payload);
      }

      dispatch(
        showNotif({
          message: "Quote Data updated",
          options: { variant: "success" },
        })
      );

      return true;
    } catch (error: unknown) {
      if (error instanceof Error) {
        dispatch(
          showNotif({
            message: error.message,
            options: { variant: "error" },
          })
        );
      }
      return false;
    }
  };

  if (!params.quoteType || !VQT.includes(params.quoteType as QuoteType)) {
    return (
      <Container maxWidth="md">
        <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
          <Typography
            variant="h6"
            component="h2"
            gutterBottom
            textAlign="center"
          >
            Quote Data
          </Typography>
          <Typography variant="body1" gutterBottom textAlign="center">
            Please select a quote type from the sidebar.
          </Typography>
        </Paper>
      </Container>
    );
  }

  return (
    <QuoteData
      onSave={handleSave}
      loading={!data || loadingModifiedDates || loadingQuoteData}
      saving={savingQuoteData}
      data={data}
      title={QDI[params.quoteType as QuoteType]?.title}
      color={QDI[params.quoteType as QuoteType]?.color}
      // optionalExtraDefaults={optionalExtraDefaults}
      lastModified={lastModifiedDate ? new Date(lastModifiedDate) : null}
    />
  );
}

export default Data;

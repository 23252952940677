import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Font,
  Image,
} from "@react-pdf/renderer";
import addMonths from "date-fns/addMonths";
import format from "date-fns/format";
import { PageHeader } from "../PDFQuoteDocument/Page";
import type {
  PDFClientDetails,
  PDFQuoteDetails,
  PDFAgentDetails,
  PDFQuoteVehicleDetails,
} from "../../../../lib/types";
import styles from "../PDFQuoteDocument/styles";
import { useNumberFormatter } from "../../../../hooks";
import { VEHICLE_QUOTE_TYPES } from "../../../../lib/constants";
import StampImage from "../../../../_assets/TPAL-CoC-Stamp.png";

Font.registerHyphenationCallback((word) => [word]);

interface Props {
  clientDetails: PDFClientDetails;
  quoteDetails: PDFQuoteDetails;
  agentDetails: PDFAgentDetails;
}

const tableStyles = StyleSheet.create({
  table: {
    display: "flex" as const,
    width: "auto",
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
  },
  tableColLeft: {
    width: "25%",
  },
  tableColRight: {
    width: "75%",
  },
});

function ItemRow({ title, text }: { title: string; text: string }) {
  return (
    <View style={tableStyles.tableRow}>
      <View style={tableStyles.tableColLeft}>
        <Text style={[styles.textSmall, { fontFamily: "Helvetica-Bold" }]}>
          {title}:
        </Text>
      </View>
      <View style={tableStyles.tableColRight}>
        <Text style={[styles.textSmall]}>{text}</Text>
      </View>
    </View>
  );
}

interface CoCPageProps {
  agentDetails: PDFAgentDetails;
  product: PDFQuoteVehicleDetails;
  reference: PDFQuoteDetails["reference"];
  acceptedDate: PDFQuoteDetails["dates"]["acceptedDate"];
  clientName: PDFClientDetails["clientFirstName"];
}

function CoCPage({
  agentDetails,
  product,
  reference,
  acceptedDate,
  clientName,
}: CoCPageProps) {
  const decimalFormatter = useNumberFormatter({
    style: "decimal",
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  });
  const _acceptedDate = format(acceptedDate || new Date(), "dd MMMM, yyyy");
  const _acceptedDateSignature = format(
    acceptedDate || new Date(),
    "MMMM dd yyy"
  );
  const _acceptedDatePlus12 = format(
    addMonths(acceptedDate || new Date(), 12),
    "dd MMMM, yyyy"
  );

  const { vehicle, insuranceValues } = product;
  const vehicleColourTrim = [vehicle.vehicleColour, vehicle.vehicleTrim]
    .filter(Boolean)
    .join(" / ");

  const vehicleText = (text: string | null) => {
    if (!text) return "Not Provided";

    if (text.trim().length) {
      return text;
    }

    return "Not Provided";
  };

  const details = [
    {
      title: "The Insured",
      text: `${clientName} & Credit Corporation Finance Limited; for their Respective Rights and Interests.`,
    },
    {
      title: "Class of Insurance",
      text: `${product.title}`,
    },
    {
      title: "Policy Number",
      text: `${reference}`,
    },
    {
      title: "Period of Insurance",
      text: `${_acceptedDate} at 4:00 PM (Local Time) to ${_acceptedDatePlus12} at 4:00 PM (Local Time)`,
    },
    {
      title: "Interest Insured",
      text: "Loss of and/or damage to registered vehicle insured by this policy and legal liability in respect of third-party property damage.",
    },
    {
      title: "Covering",
      text: `Make/Model: ${vehicle.vehicleMake} ${vehicle.vehicleModel} ${
        vehicle.vehicleYear
      }\nColour/Trim:	${
        vehicleColourTrim || "Not Provided"
      }\nEngine No.:	${vehicleText(
        vehicle.vehicleEngineNumber
      )}\nChassis No.:	${vehicleText(
        vehicle.vehicleChassisNumber
      )}\nRegistration No.:	${vehicleText(vehicle.vehicleRegistrationNumber)}`,
    },
    {
      title: "Limits of Liability",
      text: `Section 1 - Own Loss/Damage;\nThe vehicle is insured for a maximum amount of K${decimalFormatter.format(
        insuranceValues.sumInsured
      )} or\nmarket value whichever is the lesser.\n\nSection 2 – Legal Liability;\nThird party legal liability excluding bodily injury up to a maximum\nlimit of K1,000,000.`,
    },
    {
      title: "Deductible(s)",
      text: `• Standard/Basic - K${product.insuranceValues.standardExcess} each and every claim.\n• Total Loss - K${product.insuranceValues.totalLossExcess} each and every claim.`,
    },
    {
      title: "Major Exclusions",
      text: "Riots, Strikes, Civil Commotion, Malicious Damage, Looting, Terrorism, Tribal Fighting, Flood, Tribal or Ethnic Clashes, Earthquake in ENBP, Wear & Tear, Electrical and/or Mechanical Breakdown, Theft of Parts Or Abandonment of whole vehicle, Theft by Family/Relatives, Driver Intoxication (Drug & Alcohol), Carriage of Fuel/Hazardous goods, Consequential Loss and and as per policy.",
    },
    {
      title: "Situation/Location",
      text: "Anywhere in Papua New Guinea.",
    },
    {
      title: "Insurer Capacity",
      text: "100.00%",
    },
  ];

  return (
    <Page size="A4" style={styles.page}>
      <PageHeader {...agentDetails} />

      {/* Title */}
      <View
        style={[styles.marginBottom14, styles.flexRow, styles.justifyCenter]}
      >
        <Text style={styles.textBold}>CERTIFICATE OF CURRENCY</Text>
      </View>

      {/* Intro */}
      <View style={styles.marginBottom12}>
        <Text style={styles.textSmall}>
          {agentDetails.brokerageName || "Trans Pacific Assurance Limited"} is
          pleased to confirm the following insurance coverage on your behalf:
        </Text>
      </View>

      {/* Insurance Details */}
      <View style={[tableStyles.table, styles.marginBottom12]}>
        {details.map((item) => (
          <React.Fragment key={item.title}>
            <ItemRow title={item.title} text={item.text} />
            <View style={{ height: 10 }} />
          </React.Fragment>
        ))}
      </View>

      <View style={[styles.containerWithBorder, styles.marginBottom12]}>
        <Text style={styles.textInfo}>
          Full details of policy coverage together with the terms and conditions
          contained in the policy document are available for review on request.
        </Text>
      </View>

      <View style={{ flexDirection: "row" }}>
        <View>
          <View style={styles.marginBottom30}>
            <Text style={styles.textSmallBold}>
              Signed for and on Behalf Of
            </Text>
            <Text style={styles.textSmallBold}>
              {agentDetails.brokerageName || "Trans Pacific Assurance Limited"}
            </Text>
          </View>
          <View style={styles.marginBottom12}>
            <Text style={styles.textNormal}>
              ..........................................................
            </Text>
          </View>
          <View style={[styles.flexRow]}>
            <Text style={styles.textSmallBold}>Dated:</Text>
            <View style={{ width: 10 }} />
            <Text style={styles.textSmall}>{_acceptedDateSignature}</Text>
          </View>
        </View>
        <View style={{ width: 100, height: 100 }}>
          <Image
            source={StampImage}
            style={{ width: "auto", height: "auto" }}
          />
        </View>
      </View>
    </Page>
  );
}

function PDFCertificateOfCurrencyDocument({
  clientDetails,
  quoteDetails,
  agentDetails,
}: Props) {
  const clientName = clientDetails.isPrivate
    ? `${clientDetails.clientTitle} ${clientDetails.clientFirstName} ${clientDetails.clientLastName}`
    : `${clientDetails.clientFirstName}`;

  // Certificate of Currency only applies to Motor Vehicle products
  return (
    <Document>
      {quoteDetails.products
        .filter((p) => VEHICLE_QUOTE_TYPES.includes(p.quoteType))
        .map((product, index) => (
          <CoCPage
            key={index}
            agentDetails={agentDetails}
            product={product as PDFQuoteVehicleDetails}
            reference={quoteDetails.reference}
            acceptedDate={quoteDetails.dates.acceptedDate}
            clientName={clientName}
          />
        ))}
    </Document>
  );
}

export default PDFCertificateOfCurrencyDocument;

import { createTheme, ThemeProvider } from "@mui/material/styles";
import { blue } from "@mui/material/colors";
import CssBaseline from "@mui/material/CssBaseline";
import { Routes, Route, Navigate } from "react-router-dom";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import PageNotFound from "../components/PageNotFound";
import RoutingError from "./RoutingError";

/* Auth */
import CheckAccess from "./CheckAccess";
/* Notifications */
import { SnackbarProvider } from "notistack";
import SnackbarNotifications from "./SnackbarNotifications";
/* Containers */
import Layout from "./PageLayout";

// Quotes
import CreateQuotes from "./QuotesCreate";
import ReportQuotes from "./QuotesReports";

// Settings - Users / Brokerages
import SettingsUsers from "./SettingsUsers";
import SettingsBrokerages from "./SettingsBrokerages";

// Settings - Email
import SettingsEmail from "./SettingsEmail";

// Settings - Quote Data
import QuoteData from "./QuoteData";

// User / Authentication
import MyProfile from "./MyProfile";
import Login from "./Login";
import Logout from "./Logout";

import { selectMe } from "../lib/store/features/user.slice";
import { useAppSelector } from "../hooks";

const mdTheme = createTheme({
  palette: {
    primary: blue,
  },
});

function App() {
  const myRole = useAppSelector(selectMe)?.role ?? "";

  return (
    <SnackbarProvider
      preventDuplicate
      maxSnack={4}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
    >
      <SnackbarNotifications />
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <ThemeProvider theme={mdTheme}>
          <CssBaseline />
          <Routes>
            <Route
              errorElement={<RoutingError />}
              path="/"
              element={<Layout />}
            >
              <Route
                index
                element={
                  <CheckAccess requiredRole={["agent", "admin", "superadmin"]}>
                    <Navigate
                      to={
                        myRole === "superadmin"
                          ? "/quotes/reports"
                          : "/quotes/create"
                      }
                    />
                  </CheckAccess>
                }
              ></Route>

              {/* /quotes */}
              <Route path="quotes">
                {/* /quotes/create */}
                <Route path="create">
                  <Route
                    index
                    element={
                      <CheckAccess
                        requiredRole={["agent", "admin", "superadmin"]}
                      >
                        <CreateQuotes />
                      </CheckAccess>
                    }
                  />
                </Route>

                {/* /quotes/reports */}
                <Route
                  path="reports"
                  element={
                    <CheckAccess
                      requiredRole={["agent", "admin", "superadmin"]}
                    >
                      <ReportQuotes />
                    </CheckAccess>
                  }
                />

                {/* /quotes/:quoteId */}
                {/* <Route
                  path=":quoteId"
                  element={
                    <CheckAccess
                      requiredRole={["agent", "admin", "superadmin"]}
                    >
                      <p>quote by id</p>
                    </CheckAccess>
                  }
                /> */}
              </Route>

              {/* /settings */}
              <Route path="settings">
                {/* /settings/quote-data */}
                <Route path="quote-data">
                  <Route
                    index
                    element={
                      <CheckAccess requiredRole={["superadmin"]}>
                        <Navigate to="business" />
                      </CheckAccess>
                    }
                  />

                  {/* /settings/quote-data/:quote-type */}
                  <Route
                    path=":quoteType"
                    element={
                      <CheckAccess requiredRole={["superadmin"]}>
                        <QuoteData />
                      </CheckAccess>
                    }
                  />
                </Route>

                {/* /settings/brokerages */}
                <Route
                  path="brokerages"
                  element={
                    <CheckAccess requiredRole={["superadmin"]}>
                      <SettingsBrokerages />
                    </CheckAccess>
                  }
                >
                  {/* /settings/brokerages/:brokerageId */}
                  <Route
                    path=":brokerageId"
                    element={
                      <CheckAccess requiredRole={["superadmin"]}>
                        <p>view brokerage by id</p>
                      </CheckAccess>
                    }
                  />
                  {/* /brokerages/:brokerageId/edit */}
                  <Route
                    path=":brokerageId/edit"
                    element={
                      <CheckAccess requiredRole={["superadmin"]}>
                        <p>edit brokerage by id</p>
                      </CheckAccess>
                    }
                  />
                </Route>

                {/* /settings/users */}
                <Route
                  path="users"
                  element={
                    <CheckAccess requiredRole={["superadmin"]}>
                      <SettingsUsers />
                    </CheckAccess>
                  }
                />

                {/* /settings/email */}
                <Route
                  path="email"
                  element={
                    <CheckAccess requiredRole={["superadmin"]}>
                      <SettingsEmail />
                    </CheckAccess>
                  }
                />
              </Route>

              {/* /my-profile */}
              <Route
                path="my-profile"
                element={
                  <CheckAccess requiredRole={["admin", "agent", "superadmin"]}>
                    <MyProfile />
                  </CheckAccess>
                }
              />
            </Route>
            <Route path="login" element={<Login />} />
            <Route path="logout" element={<Logout />} />
            <Route
              path="*"
              element={
                <PageNotFound homeLink={!!myRole ? "/quotes/create" : ""} />
              }
            />
          </Routes>
        </ThemeProvider>
      </LocalizationProvider>
    </SnackbarProvider>
  );
}

export default App;

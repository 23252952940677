import * as React from "react";
import { alpha, styled } from "@mui/material/styles";
import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import ListItemButton, {
  ListItemButtonProps,
} from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import PeopleIcon from "@mui/icons-material/People";
import EmailIcon from "@mui/icons-material/Email";
import BusinessIcon from "@mui/icons-material/Business";
import SvgIcon from "@mui/material/SvgIcon";
import AssessmentIcon from "@mui/icons-material/Assessment";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import ArticleIcon from "@mui/icons-material/Article";
import LogoutIcon from "@mui/icons-material/Logout";
import PersonIcon from "@mui/icons-material/Person";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

export const APP_PATHS = {
  createQuote: "/quotes/create",
  reportQuotes: "/quotes/reports",
  settingsQuoteData: "/settings/quote-data",
  settingsBrokerages: "/settings/brokerages",
  settingsUsers: "/settings/users",
  settingsEmail: "/settings/email",
  myProfile: "/my-profile",
  logout: "/logout",
};

type callbackType = (path: string) => void;

const _nav = (onClick: callbackType) => (path: string) => () => {
  onClick(path);
};

interface NestedListProps {
  mainMenu: {
    title: string;
    Icon: typeof SvgIcon;
    selected?: boolean;
  };
  nestedMenu: {
    selected: boolean;
    onClick: () => void;
    Icon: typeof SvgIcon;
    title: string;
  }[];
  openDefault?: boolean;
}

const ListItemButtonMain = styled(ListItemButton)<ListItemButtonProps>(
  ({ theme }) => ({
    "&.Mui-selected": {
      backgroundColor: alpha(theme.palette.grey[400], 0.16),
    },
  })
);

function NestedList({ mainMenu, nestedMenu, openDefault }: NestedListProps) {
  const [open, setOpen] = React.useState(openDefault || false);

  const handleToggleOpen = () => {
    setOpen(!open);
  };

  const { Icon: MainIcon, title, selected: mainSelected } = mainMenu;

  return (
    <>
      <ListItemButtonMain selected={mainSelected} onClick={handleToggleOpen}>
        <ListItemIcon>
          <MainIcon />
        </ListItemIcon>
        <ListItemText primary={title} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButtonMain>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {nestedMenu.map(({ onClick, Icon, title, selected }) => (
            <ListItemButton
              key={title}
              selected={selected}
              onClick={onClick}
              sx={{ pl: 4 }}
            >
              <ListItemIcon>
                <Icon />
              </ListItemIcon>
              <ListItemText primary={title} />
            </ListItemButton>
          ))}
        </List>
      </Collapse>
    </>
  );
}

// export const quotesNavLinks = (
//   onClick: callbackType,
//   url: string,
//   openDefault?: boolean
// ) => (
//   <NestedList
//     openDefault={openDefault}
//     mainMenu={{
//       title: "Create a Quote",
//       Icon: ArticleIcon,
//       selected: !!url.match(RegExp(`^${APP_PATHS.createQuote}`)),
//     }}
//     nestedMenu={[
//       {
//         selected: !!url.match(RegExp(`^${APP_PATHS.createQuote}/business`)),
//         onClick: _nav(onClick)(`${APP_PATHS.createQuote}/business`),
//         Icon: ChevronRightIcon,
//         title: "Business",
//       },
//       {
//         selected: !!url.match(RegExp(`^${APP_PATHS.createQuote}/commercial`)),
//         onClick: _nav(onClick)(`${APP_PATHS.createQuote}/commercial`),
//         Icon: ChevronRightIcon,
//         title: "Commercial",
//       },
//       {
//         selected: !!url.match(RegExp(`^${APP_PATHS.createQuote}/pmv`)),
//         onClick: _nav(onClick)(`${APP_PATHS.createQuote}/pmv`),
//         Icon: ChevronRightIcon,
//         title: "PMV",
//       },
//       {
//         selected: !!url.match(RegExp(`^${APP_PATHS.createQuote}/private`)),
//         onClick: _nav(onClick)(`${APP_PATHS.createQuote}/private`),
//         Icon: ChevronRightIcon,
//         title: "Private",
//       },
//       {
//         selected: !!url.match(RegExp(`^${APP_PATHS.createQuote}/taxihirecar`)),
//         onClick: _nav(onClick)(`${APP_PATHS.createQuote}/taxihirecar`),
//         Icon: ChevronRightIcon,
//         title: "Taxi - Hire Car",
//       },
//     ]}
//   />
// );
export const quotesNavLinks = (
  onClick: callbackType,
  url: string,
  openDefault?: boolean
) => (
  <React.Fragment>
    <ListItemButton
      selected={!!url.match(RegExp(`^${APP_PATHS.createQuote}`))}
      onClick={_nav(onClick)(APP_PATHS.createQuote)}
    >
      <ListItemIcon>
        <ArticleIcon />
      </ListItemIcon>
      <ListItemText primary="Quotes" />
    </ListItemButton>
  </React.Fragment>
);

export const quoteSearchNavLinks = (onClick: callbackType, url: string) => (
  <React.Fragment>
    <ListItemButton
      selected={url === APP_PATHS.reportQuotes}
      onClick={_nav(onClick)(APP_PATHS.reportQuotes)}
    >
      <ListItemIcon>
        <FindInPageIcon />
      </ListItemIcon>
      <ListItemText primary="Reports" />
    </ListItemButton>
  </React.Fragment>
);

export const settingsNavLinks = (
  onClick: callbackType,
  url: string,
  openDefault?: boolean
) => (
  <React.Fragment>
    <NestedList
      openDefault={openDefault}
      mainMenu={{
        title: "Vehicle Data",
        Icon: AssessmentIcon,
        selected: !!url.match(RegExp(`^${APP_PATHS.settingsQuoteData}`)),
      }}
      nestedMenu={[
        {
          selected: !!url.match(
            RegExp(`^${APP_PATHS.settingsQuoteData}/business`)
          ),
          onClick: _nav(onClick)(`${APP_PATHS.settingsQuoteData}/business`),
          Icon: ChevronRightIcon,
          title: "Business",
        },
        {
          selected: !!url.match(
            RegExp(`^${APP_PATHS.settingsQuoteData}/commercial`)
          ),
          onClick: _nav(onClick)(`${APP_PATHS.settingsQuoteData}/commercial`),
          Icon: ChevronRightIcon,
          title: "Commercial",
        },
        {
          selected: !!url.match(RegExp(`^${APP_PATHS.settingsQuoteData}/pmv`)),
          onClick: _nav(onClick)(`${APP_PATHS.settingsQuoteData}/pmv`),
          Icon: ChevronRightIcon,
          title: "PMV",
        },
        {
          selected: !!url.match(
            RegExp(`^${APP_PATHS.settingsQuoteData}/private`)
          ),
          onClick: _nav(onClick)(`${APP_PATHS.settingsQuoteData}/private`),
          Icon: ChevronRightIcon,
          title: "Private",
        },
        {
          selected: !!url.match(
            RegExp(`^${APP_PATHS.settingsQuoteData}/taxihirecar`)
          ),
          onClick: _nav(onClick)(`${APP_PATHS.settingsQuoteData}/taxihirecar`),
          Icon: ChevronRightIcon,
          title: "Taxi Hire Car",
        },
      ]}
    />

    <ListItemButton
      selected={!!url.match(RegExp(`^${APP_PATHS.settingsBrokerages}`))}
      onClick={_nav(onClick)(APP_PATHS.settingsBrokerages)}
    >
      <ListItemIcon>
        <BusinessIcon />
      </ListItemIcon>
      <ListItemText primary="Brokerages" />
    </ListItemButton>

    <ListItemButton
      selected={!!url.match(RegExp(`^${APP_PATHS.settingsUsers}`))}
      onClick={_nav(onClick)(APP_PATHS.settingsUsers)}
    >
      <ListItemIcon>
        <PeopleIcon />
      </ListItemIcon>
      <ListItemText primary="Users" />
    </ListItemButton>
    <ListItemButton
      selected={!!url.match(RegExp(`^${APP_PATHS.settingsEmail}`))}
      onClick={_nav(onClick)(APP_PATHS.settingsEmail)}
    >
      <ListItemIcon>
        <EmailIcon />
      </ListItemIcon>
      <ListItemText primary="Email" />
    </ListItemButton>
  </React.Fragment>
);

export const userNavLinks = (onClick: callbackType, url: string) => (
  <React.Fragment>
    <ListItemButton
      selected={!!url.match(RegExp(`^${APP_PATHS.myProfile}`))}
      onClick={_nav(onClick)(APP_PATHS.myProfile)}
    >
      <ListItemIcon>
        <PersonIcon />
      </ListItemIcon>
      <ListItemText primary="My Profile" />
    </ListItemButton>
    <ListItemButton onClick={_nav(onClick)(APP_PATHS.logout)}>
      <ListItemIcon>
        <LogoutIcon />
      </ListItemIcon>
      <ListItemText primary="Logout" />
    </ListItemButton>
  </React.Fragment>
);

import {
  IAuthTokens,
  TokenRefreshRequest,
  applyAuthTokenInterceptor,
} from "axios-jwt";
import axios, { AxiosRequestConfig } from "axios";

class BrowserStorageService {
  private _storage: Storage;
  private _key: string;

  constructor(storage: Storage, key: string) {
    this._storage = storage;
    this._key = key;
  }

  remove(key: string) {
    this._storage.removeItem(this._key);
  }

  get(key: string) {
    return this._storage.getItem(this._key);
  }

  set(key: string, value: string) {
    this._storage.setItem(this._key, value);
  }
}

const { REACT_APP_API_HOST: BASE_URL } = process.env;

// 1. Create an axios instance that you wish to apply the interceptor to
export const axiosInstance = axios.create({ baseURL: BASE_URL });

// 2. Define token refresh function.
const requestRefresh: TokenRefreshRequest = async (
  refreshToken: string
): Promise<IAuthTokens | string> => {
  const response = await axios.post(`${BASE_URL}/refreshIdToken`, {
    refreshToken,
  });

  return response.data.idToken;
};

// New to 2.2.0+: initialize with storage: localStorage/sessionStorage/nativeStorage. Helpers: getBrowserLocalStorage, getBrowserSessionStorage
const getStorage = () =>
  new BrowserStorageService(
    window.localStorage,
    `tpal-auth-tokens-${process.env.NODE_ENV}`
  );

// 3. Add interceptor to your axios instance
applyAuthTokenInterceptor(axiosInstance, {
  requestRefresh,
  getStorage,
  header: "X-Auth-Token", // servers are stripping away the Authorization header, so send it via x-auth-token
  headerPrefix: "Bearer ",
});

export function request({
  headers,
  timeout,
  ...requestConfig
}: AxiosRequestConfig) {
  const config = {
    ...requestConfig,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      ...(headers && { ...headers }),
    },
    timeout: timeout || 30000,
  };

  return axiosInstance(config as any);
}

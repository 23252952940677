import React from "react";
import VehicleProductForm from "../../../components/Quote/VehicleProductForm";
import type {
  QuoteFormDataObj,
  PolicyFieldType,
  ClientVehicleFieldType,
  QuoteType,
  QuoteFormState,
} from "../../../lib/types";
import {
  calculatePremium,
  calculateTppdLimitIncreaseAmount,
} from "../../../lib/helpers";

interface Props {
  title: string;
  subTitle: string;
  quoteTitle: string;
  color: string;
  existingData?: QuoteFormState;
  quoteData?: QuoteFormDataObj[QuoteType];
  withNamedDriverDiscount: boolean;
  isLoadingData: boolean;
  isLoadingLastModifiedDates: boolean;
}

class VehicleQuoteForm extends React.Component<Props, QuoteFormState> {
  _isMounted = false;

  state = {
    clientVehicleValues: {
      vehicleMake: "",
      vehicleModel: "",
      vehicleYear: "",
      vehicleColour: "",
      vehicleTrim: "",
      vehicleEngineNumber: "",
      vehicleChassisNumber: "",
      vehicleRegistrationNumber: "",
    },
    insuranceValues: {
      cFreeDiscount: "No previous insurance",
      cFreeDiscountAmount: 0,
      aExcessDiscount: 0,
      aExcessDiscountAmount: 0,
      nDriverDiscount: "No",
      nDriverDiscountAmount: 0,
      sumInsured: 0,
      premium: 0,
      standardExcess: 0,
      totalLossExcess: 0,
      inexperiencedDriverExcess: 0,
      driverNames: "",
      tppdLimitInc: 0,
      tppdLimitIncAmount: 0,
    },
    calculations: {
      sumInsured: 0,
      sumInsuredPremium: 0,
      discountPercentage: 0,
    },
    quoteYears: 1,
  };

  componentDidMount() {
    this._isMounted = true;

    this.initExistingData();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  // React.Component<Props, State, any>.setState<keyof State>(state: State | ((prevState: Readonly<State>, props: Readonly<Props>) => State | Pick<State, keyof State> | null) | Pick<...> | null, callback?: (() => void) | undefined): void

  updateState = (
    state:
      | QuoteFormState
      | ((
          prevState: Readonly<QuoteFormState>,
          props: Readonly<Props>
        ) =>
          | QuoteFormState
          | Pick<QuoteFormState, keyof QuoteFormState>
          | null),
    callback?: (() => void) | undefined
  ) => {
    if (this._isMounted) {
      this.setState(state, () => {
        if (callback && typeof callback === "function") callback();
      });
    }
  };

  initExistingData = () => {
    const { existingData } = this.props;

    if (existingData) {
      this.updateState(existingData);
    }
  };

  /**
   * This is used for the parent node to get access to this component's state values (to store in redux)
   * @returns State
   */
  dumpData = () => {
    return this.state;
  };

  /**
   * Calculation functions
   */
  calculateFields = (updatedField: PolicyFieldType) => {
    if (updatedField === "sumInsured") {
      // calculate premium and standard excess
      const { insuranceValues } = this.state;
      const premium = this.calculatePremiumValue(insuranceValues.sumInsured);
      const standardExcess = this.calculateStandardExcess();
      const totalLossExcess = this.calcualeExcessTotalLoss(standardExcess);
      const tppdLimitIncAmount = this.calculateTppdLimitIncreaseValue(premium);
      this.updateState((vals) => ({
        ...vals,
        insuranceValues: {
          ...vals.insuranceValues,
          premium,
          standardExcess,
          totalLossExcess,
          tppdLimitIncAmount,
        },
        calculations: {
          ...vals.calculations,
          sumInsured: +vals.insuranceValues.sumInsured,
          sumInsuredPremium: premium,
        },
      }));
    }

    if (updatedField === "cFreeDiscount") {
      // calculate claims free discount
      const cFreeDiscountAmount = this.calculateClaimsFreeDiscountAmount();

      this.updateState((vals) => ({
        ...vals,
        insuranceValues: {
          ...vals.insuranceValues,
          cFreeDiscountAmount,
        },
        calculations: {
          ...vals.calculations,
          discountPercentage:
            cFreeDiscountAmount +
            vals.insuranceValues.aExcessDiscountAmount +
            vals.insuranceValues.nDriverDiscountAmount,
        },
      }));
    }

    if (updatedField === "aExcessDiscount") {
      // calculate additional excess discount
      const aExcessDiscountAmount =
        this.calculateAdditionalExcessDiscountAmount();

      this.updateState((vals) => ({
        ...vals,
        insuranceValues: {
          ...vals.insuranceValues,
          aExcessDiscountAmount,
        },
        calculations: {
          ...vals.calculations,
          discountPercentage:
            vals.insuranceValues.cFreeDiscountAmount +
            aExcessDiscountAmount +
            vals.insuranceValues.nDriverDiscountAmount,
        },
      }));
    }

    if (updatedField === "nDriverDiscount") {
      // calculate named driver discount
      const nDriverDiscountAmount = this.calculateNamedDriverDiscountAmount();

      this.updateState((vals) => ({
        ...vals,
        insuranceValues: {
          ...vals.insuranceValues,
          nDriverDiscountAmount,
        },
        calculations: {
          ...vals.calculations,
          discountPercentage:
            vals.insuranceValues.cFreeDiscountAmount +
            vals.insuranceValues.aExcessDiscountAmount +
            nDriverDiscountAmount,
        },
      }));
    }

    if (updatedField === "tppdLimitInc") {
      // calculate tppd limit increase amount
      const { premium } = this.state.insuranceValues;
      const tppdLimitIncAmount = this.calculateTppdLimitIncreaseValue(premium);

      this.updateState((vals) => ({
        ...vals,
        insuranceValues: {
          ...vals.insuranceValues,
          tppdLimitIncAmount,
        },
      }));
    }
  };

  calculateTppdLimitIncreaseValue = (premium: number) => {
    const { quoteData } = this.props;
    const { insuranceValues } = this.state;

    if (!quoteData) return 0;

    return calculateTppdLimitIncreaseAmount({
      quoteData,
      premium,
      insuranceValues,
    });
  };

  calculateNamedDriverDiscountAmount = () => {
    const { quoteData } = this.props;
    const { insuranceValues } = this.state;
    const { nDriverDiscount } = insuranceValues;

    if (!quoteData) return 0;

    const { optionalExtras } = quoteData;

    const optExtData = optionalExtras.find(
      (ex) => ex.title === "Named Driver Discount"
    );

    if (!optExtData) return 0;

    const optExtDiscount = optExtData.data.find(
      (e: any) => e.option === nDriverDiscount
    );

    if (!optExtDiscount) return 0;

    const { discountPercentage } = optExtDiscount;

    return discountPercentage;
  };

  calculateAdditionalExcessDiscountAmount = () => {
    const { quoteData } = this.props;
    const { insuranceValues } = this.state;

    if (!quoteData) return 0;

    const { discountsIncreasedExcess } = quoteData;
    const { aExcessDiscount } = insuranceValues;

    const discount = discountsIncreasedExcess.find(
      (cfd) => String(cfd.increase) === String(aExcessDiscount)
    );

    return discount?.discountPercentage ?? 0;
  };

  calculateClaimsFreeDiscountAmount = () => {
    const { quoteData } = this.props;
    const { insuranceValues } = this.state;
    const { cFreeDiscount } = insuranceValues;

    if (!quoteData) return 0;

    const { claimsFreeDiscounts } = quoteData;

    const discount = claimsFreeDiscounts.find(
      (cfd) => cfd.criteria === cFreeDiscount
    );

    // const { discountPercentage } = discount;

    return discount?.discountPercentage ?? 0;
  };

  calculatePremiumValue = (sumInsured: number) => {
    const { quoteData } = this.props;
    if (!quoteData) return 0;

    return calculatePremium({ sumInsured, quoteData });
  };

  calculateStandardExcess = () => {
    const { quoteData } = this.props;
    const { insuranceValues } = this.state;
    const { sumInsured: _sumInsured } = insuranceValues;

    if (!quoteData) return 0;

    const { standardExcess } = quoteData;

    if (!_sumInsured) return 0;

    const sumInsured =
      typeof _sumInsured === "string" ? parseInt(_sumInsured, 10) : _sumInsured;

    const index = standardExcess.length - 1;
    if (sumInsured >= standardExcess[index].minAmount) {
      const _si = sumInsured * (standardExcess[index].excess / 100);
      // Transpacific require excess to round up to the nearest 1000
      return Math.ceil(_si / 1000) * 1000;
    }

    const stdExcess = standardExcess.find((excss) => {
      const { minAmount, maxAmount } = excss;
      return sumInsured >= minAmount && sumInsured <= maxAmount;
    });

    return stdExcess?.excess ?? 0;
  };

  calcualeExcessTotalLoss = (standardExcess: number) => {
    const { quoteData } = this.props;

    if (!quoteData) return 0;

    const { excessTotalLossMultiplier } = quoteData;

    const _etl = standardExcess * excessTotalLossMultiplier.multiplier;
    // Transpacific require excess to round up to the nearest 1000
    return Math.ceil(_etl / 1000) * 1000;
  };

  /**
   * Event handlers
   */

  handleChangeInsuranceValue = ({
    field,
    value,
  }: {
    field: PolicyFieldType;
    value: string | number;
  }) => {
    const getValue = (val: string | number) => {
      const numberFields = ["sumInsured", "inexperiencedDriverExcess"];
      if (numberFields.includes(field)) {
        return +val;
      }
      return val;
    };

    this.updateState(
      (vals) => ({
        ...vals,
        insuranceValues: {
          ...vals.insuranceValues,
          [field]: getValue(value),
        },
      }),
      () => this.calculateFields(field)
    );
  };

  handleChangeClientVehicleValue = ({
    field,
    value,
  }: {
    field: ClientVehicleFieldType;
    value: string | number;
  }) => {
    this.updateState((vals) => ({
      ...vals,
      clientVehicleValues: {
        ...vals.clientVehicleValues,
        [field]: value,
      },
    }));
  };

  handleChangeQuoteYears = ({ value }: { value: number | string }) => {
    this.updateState((vals) => ({
      ...vals,
      quoteYears: +value,
    }));
  };

  render() {
    const {
      quoteData,
      title,
      subTitle,
      color,
      withNamedDriverDiscount,
      isLoadingData,
      isLoadingLastModifiedDates,
    } = this.props;
    const { calculations, insuranceValues, clientVehicleValues, quoteYears } =
      this.state;

    const cfd = quoteData?.claimsFreeDiscounts;
    const minPrem = quoteData?.minimumPremium;
    const taxes = quoteData?.taxes ?? { icl: 0, gst: 0 };
    const claimsFreeDiscounts = cfd
      ? cfd
        ? cfd.map((c) => c.criteria)
        : []
      : [];

    return (
      <VehicleProductForm
        color={color}
        withNamedDriverDiscount={withNamedDriverDiscount}
        minPremium={minPrem ? minPrem.premium : 0}
        isLoading={isLoadingData || isLoadingLastModifiedDates}
        calculations={calculations}
        calculationFunctions={{
          calculatePremium: this.calculatePremiumValue,
          calculateExtras: this.calculateTppdLimitIncreaseValue,
        }}
        taxes={taxes}
        title={title}
        subTitle={subTitle}
        claimsFreeDiscounts={claimsFreeDiscounts}
        clientVehicleValues={clientVehicleValues}
        quoteYears={quoteYears}
        insuranceValues={insuranceValues}
        onChangeInsuranceValue={this.handleChangeInsuranceValue}
        onChangeClientVehicleValue={this.handleChangeClientVehicleValue}
        onChangeQuoteYearsValue={this.handleChangeQuoteYears}
      />
    );
  }
}

export default VehicleQuoteForm;

import CalcComponent from "../../components/Quote/WorkersCompensationCalculations";
import type { WrkCmpCalculationsType, WrkCmpTaxesType } from "../../lib/types";

interface Props {
  minPremium: number;
  calculations: WrkCmpCalculationsType;
  taxes: WrkCmpTaxesType;
}

function CalculationsWrkCmp({ minPremium, calculations, taxes }: Props) {
  const { subTotal, sumInsuredPremium, optionalExtras } = calculations;
  const grandTotal = sumInsuredPremium + optionalExtras + taxes.gst + taxes.wcl;

  return (
    <CalcComponent
      minPremium={minPremium}
      sumInsuredPremium={sumInsuredPremium}
      optionalExtras={optionalExtras}
      subTotal={subTotal}
      wcl={taxes.wcl}
      gst={taxes.gst}
      grandTotal={grandTotal}
    />
  );
}

export default CalculationsWrkCmp;

export const useNumberFormatter = (options?: Intl.NumberFormatOptions) => {
  const currency = "PGK";

  const moneyFormatter = new Intl.NumberFormat(
    "en-AU",
    options
      ? { ...options }
      : {
          style: "currency",
          currency,
        }
  );

  return moneyFormatter;
};

import React, { useEffect, useState, Ref } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import WorkersCompensationForm from "../../../components/Quote/WorkersCompensationForm";
import type {
  WorkCategory,
  WorkOccupation,
  ClaimHistoryItem,
  WrkCmpOptionalExtra,
  WrkCmpQuoteFormState,
} from "../../../lib/types";
import {
  getWrkCmpCategories,
  getWrkCmpCategoryOccupations,
  selectLoadingWrkCmpCategories,
  selectLoadingWrkCmpCategoryOccupations,
  selectWrkCmpCategories,
  selectWrkCmpCategoryOccupations,
  selectWrkCmpOptionalExtras,
  makeSelectQuoteData,
} from "../../../lib/store/features/quoteData.slice";
import { enqueue as showNotif } from "../../../lib/store/features/notifications.slice";

interface Props {
  title: string;
  subTitle: string;
  quoteTitle: string;
  color: string;
  innerRef?: Ref<any>;
  existingData?: WrkCmpQuoteFormState;
}

function WrkCmpQuoteForm({
  title,
  subTitle,
  quoteTitle,
  color,
  innerRef,
  existingData,
}: Props) {
  const [values, setValues] = useState<WrkCmpQuoteFormState>({
    selectedCategory: null,
    selectedOccupation: null,
    claimsHistory: [],
    selectedOptionalExtras: {
      "Haus Krai": 0,
      "BEKIM PEI": 0,
      "Common Law": 0,
      WETHA: 0,
    },
    selectedOptionalExtraAmounts: {
      "Haus Krai": 0,
      "BEKIM PEI": 0,
      "Common Law": 0,
      WETHA: 0,
    },
    loading: true,
    wageRoll: 0,
    calculations: {
      sumInsuredPremium: 0, // Rate * Wage Roll
      optionalExtras: 0, // Haus Krai + BEKIM PEI + Common Law + WETHA
      subTotal: 0, // sumInsuredPremium + optionalExtras < minPremium ? minPremium : sumInsuredPremium + optionalExtras
    },
    taxes: {
      wcl: 0,
      gst: 0,
    },
  });
  const dispatch = useAppDispatch();
  const categories = useAppSelector(selectWrkCmpCategories);
  const wrkCmpOptionalExtras = useAppSelector(selectWrkCmpOptionalExtras);
  const occupations = useAppSelector(selectWrkCmpCategoryOccupations);
  const loadingCategories = useAppSelector(selectLoadingWrkCmpCategories);
  const loadingOccupations = useAppSelector(
    selectLoadingWrkCmpCategoryOccupations
  );
  const data = useAppSelector(makeSelectQuoteData("workers_compensation"));

  useEffect(() => {
    const init = async () => {
      // get the categories
      const result = await dispatch(getWrkCmpCategories());

      if (result.meta.requestStatus === "rejected") {
        dispatch(
          showNotif({
            message: result.payload as string,
            options: { variant: "error" },
          })
        );

        return;
      }

      // get the occupations for category with the first id
      if (result.payload && result.payload.length > 0) {
        const categoryId = existingData
          ? existingData?.selectedCategory?.id
          : result.payload[0].id;
        const occupationsResult = await dispatch(
          getWrkCmpCategoryOccupations({ categoryId })
        );

        if (occupationsResult.meta.requestStatus === "rejected") {
          dispatch(
            showNotif({
              message: result.payload as string,
              options: { variant: "error" },
            })
          );

          return;
        }

        setValues((vals) => ({
          ...vals,
          selectedCategory: result.payload[0],
          ...(!!existingData && { ...existingData }),
          loading: false,
        }));
      }
    };

    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * This is used for the parent node to get access to this component's state values (to store in redux)
   * @returns State
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const dumpData = () => {
    return values;
  };

  // assign the dumpData function to the ref
  if (innerRef) {
    // @ts-ignore
    innerRef.current = { dumpData };
  }

  const doCalculations = (latestValues: WrkCmpQuoteFormState) => {
    const {
      selectedOccupation: so,
      wageRoll,
      selectedOptionalExtras: soe,
    } = latestValues;

    const sumInsuredPremium = so?.rate ? so.rate * wageRoll : 0;

    const hausKraiIdx = soe["Haus Krai"];
    const bekimPeiIdx = soe["BEKIM PEI"];
    const commonLawIdx = soe["Common Law"];
    const wethaIdx = soe["WETHA"];
    const hausKraiDiscountPercentage =
      wrkCmpOptionalExtras["Haus Krai"].data[hausKraiIdx].discountPercentage;
    const bekimPeiDiscountPercentage =
      wrkCmpOptionalExtras["BEKIM PEI"].data[bekimPeiIdx].discountPercentage;
    const commonLawDiscountPercentage =
      wrkCmpOptionalExtras["Common Law"].data[commonLawIdx].discountPercentage;
    const wethaDiscountPercentage =
      wrkCmpOptionalExtras["WETHA"].data[wethaIdx].discountPercentage;

    // soea = selectedOptionalExtrasAmounts
    const soea = {
      "Haus Krai": Math.round(
        sumInsuredPremium * (hausKraiDiscountPercentage / 100)
      ),
      WETHA: Math.round(sumInsuredPremium * (wethaDiscountPercentage / 100)),
      "Common Law": Math.round(
        sumInsuredPremium * (commonLawDiscountPercentage / 100)
      ),
      "BEKIM PEI": Math.round(
        sumInsuredPremium * (bekimPeiDiscountPercentage / 100)
      ),
    };

    const _optionalExtrasValue = Object.keys(soea).reduce(
      (acc: number, key) => {
        const amount: number = soea[key as WrkCmpOptionalExtra];

        return acc + amount;
      },
      0
    );

    const minPremium = data?.minimumPremium?.premium;
    const subTotal =
      minPremium && !!so?.rate // if so?.rate === null then this is an occupation for which we cannot provide a quote
        ? sumInsuredPremium + _optionalExtrasValue < minPremium
          ? minPremium
          : sumInsuredPremium + _optionalExtrasValue
        : 0;

    const wclTaxRate = data?.taxes?.wcl ?? 0;
    const gstTaxRate = data?.taxes?.gst ?? 0;

    return {
      calculations: {
        sumInsuredPremium,
        optionalExtras: _optionalExtrasValue,
        subTotal,
      },
      selectedOptionalExtraAmounts: soea,
      taxes: {
        wcl: Math.round(subTotal * (wclTaxRate / 100)),
        gst: Math.round(subTotal * (gstTaxRate / 100)),
      },
    };
  };

  const handleChangeCategory = async ({
    categoryId,
  }: {
    categoryId: WorkCategory["id"];
  }) => {
    const selectedCategory = categories.find(
      (category) => category.id === categoryId
    );

    if (!selectedCategory || categoryId === values.selectedCategory?.id) {
      return;
    }

    setValues((vals) => ({
      ...vals,
      selectedCategory: selectedCategory,
      selectedOccupation: null,
      ...doCalculations({ ...vals, selectedOccupation: null }),
    }));

    const result = await dispatch(getWrkCmpCategoryOccupations({ categoryId }));

    if (result.meta.requestStatus === "rejected") {
      dispatch(
        showNotif({
          message: result.payload as string,
          options: { variant: "error" },
        })
      );

      return;
    }
  };

  const handleChangeOccupation = async ({
    occupationId,
  }: {
    occupationId: WorkOccupation["id"];
  }) => {
    const selectedOccupation = occupations.find(
      (occupation) => occupation.id === occupationId
    );

    if (!selectedOccupation) {
      return;
    }

    setValues((vals) => ({
      ...vals,
      selectedOccupation: selectedOccupation,
      ...doCalculations({ ...vals, selectedOccupation }),
    }));
  };

  const handeChangeWageRollText = ({ value }: { value: number }) => {
    setValues((vals) => {
      return {
        ...vals,
        wageRoll: value,
        ...doCalculations({ ...vals, wageRoll: value }),
      };
    });
  };

  const handleAddClaimsHistoryItem = () => {
    setValues((vals) => ({
      ...vals,
      claimsHistory: [
        ...vals.claimsHistory,
        {
          id: vals.claimsHistory.length + 1,
          year: vals.claimsHistory.length + 1,
          claim_count: 0,
          claim_amount: 0,
        },
      ],
    }));
  };

  const handleDeleteClaimsHistoryItem = (id: ClaimHistoryItem["id"]) => {
    setValues((vals) => ({
      ...vals,
      claimsHistory: vals.claimsHistory
        .filter((item) => item.id !== id)
        .map((item, index) => ({
          ...item,
          year: index + 1,
        })),
    }));
  };

  const handleChangeClaimHistoryItemText = ({
    id,
    field,
    value,
  }: {
    id: ClaimHistoryItem["id"];
    field: "claim_count" | "claim_amount";
    value: any;
  }) => {
    setValues((vals) => ({
      ...vals,
      claimsHistory: vals.claimsHistory.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            [field]: value,
          };
        }

        return item;
      }),
    }));
  };

  const handleChangeOptionalExtra = ({
    extra,
    optionIndex,
  }: {
    extra: WrkCmpOptionalExtra;
    optionIndex: number;
  }) => {
    setValues((vals) => {
      const selectedOptionalExtras = {
        ...vals.selectedOptionalExtras,
        [extra]: optionIndex,
      };

      return {
        ...vals,
        selectedOptionalExtras: {
          ...vals.selectedOptionalExtras,
          [extra]: optionIndex,
        },
        ...doCalculations({ ...vals, selectedOptionalExtras }),
      };
    });
  };

  return (
    <WorkersCompensationForm
      color={color}
      subTitle={subTitle}
      title={title}
      isLoadingMain={values.loading}
      isLoadingCategories={loadingCategories}
      isLoadingOccupations={loadingOccupations}
      selectedOccupation={values.selectedOccupation}
      selectedCategory={values.selectedCategory}
      selectedOptionalExtras={values.selectedOptionalExtras}
      selectedOptionalExtraAmounts={values.selectedOptionalExtraAmounts}
      wageRoll={values.wageRoll}
      claimsHistory={values.claimsHistory}
      categories={categories}
      optionalExtras={wrkCmpOptionalExtras}
      occupations={occupations}
      minPremium={data?.minimumPremium?.premium ?? 0}
      calculations={values.calculations}
      taxes={values.taxes}
      onChangeCategory={handleChangeCategory}
      onChangeOccupation={handleChangeOccupation}
      onChangeWageRollText={handeChangeWageRollText}
      onAddClaimsHistoryItem={handleAddClaimsHistoryItem}
      onDeleteClaimsHistoryItem={handleDeleteClaimsHistoryItem}
      onChangeClaimHistoryClaimItemText={handleChangeClaimHistoryItemText}
      onChangeOptionalExtra={handleChangeOptionalExtra}
    />
  );
}

export default React.forwardRef((props: Props, ref?: Ref<any>) => (
  <WrkCmpQuoteForm {...props} innerRef={ref} />
));

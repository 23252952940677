import { request } from "../index";
import { apiUrls } from "../api-urls";
import type { AppSettings, EmailSettings } from "../../types";

const urls = apiUrls.settings;

/*
 * Settings API
 ** */

export function sendTestEmail({
  from_address,
  to_address,
}: {
  from_address: string;
  to_address: string;
}) {
  return request({
    url: urls.emailTest,
    method: "POST",
    data: {
      from_address,
      to_address,
    },
  });
}

export function fetchAppSettings() {
  return request({
    url: urls.app,
    method: "GET",
  });
}

export function fetchEmailSettings() {
  return request({
    url: urls.email,
    method: "GET",
  });
}

export function updateAppSettingValue({
  key,
  value,
}: {
  key: keyof AppSettings["quote_settings"];
  value: string;
}) {
  return request({
    url: urls.app,
    method: "PUT",
    data: { key, value },
  });
}

export function updateEmailSettings(settings: EmailSettings) {
  return request({
    url: urls.email,
    method: "PUT",
    data: { ...settings },
  });
}

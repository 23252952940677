import { Text, View } from "@react-pdf/renderer";
import { useNumberFormatter } from "../../../../../hooks";
import type { PDFQuoteWrkCmpDetails } from "../../../../../lib/types";
import styles from "../styles";

interface Props {
  quoteDetails: {
    minPremium: PDFQuoteWrkCmpDetails["minPremium"];
    sumInsuredPremium: PDFQuoteWrkCmpDetails["sumInsuredPremium"];
    optionalExtras: PDFQuoteWrkCmpDetails["optionalExtras"];
    subTotal: PDFQuoteWrkCmpDetails["subTotal"];
    gst: PDFQuoteWrkCmpDetails["gst"];
    wcl: PDFQuoteWrkCmpDetails["wcl"];
  };
}

function WrkCmpTotals({ quoteDetails }: Props) {
  const { minPremium, sumInsuredPremium, optionalExtras, subTotal, gst, wcl } =
    quoteDetails;

  const moneyFormatter = useNumberFormatter();

  const fields = [
    {
      id: "sumInsuredPremium",
      name: "Sum Insured Premium",
      value: sumInsuredPremium,
    },
    {
      id: "optionalExtras",
      name: "Plus Optional Extras",
      value: optionalExtras,
      indent: true,
    },
    {
      id: "subTotal",
      name: `Sub Total\nMinimum premium ${moneyFormatter.format(minPremium)}`,
      value: subTotal,
      indent: true,
    },
    {
      id: "gst",
      name: "GST",
      value: gst,
    },
    {
      id: "wcl",
      name: "WCL",
      value: wcl,
    },
    {
      id: "grandtT",
      name: "Grand Total",
      value: sumInsuredPremium + optionalExtras + gst + wcl,
      isTotal: true,
    },
  ];

  return (
    <>
      {/* Quotation Totals */}
      <View style={[styles.subSectionHeadingContainer, styles.marginBottom8]}>
        <Text style={[styles.textNormal, styles.textBlue]}>
          Quotation for Workers Compensation Policy
        </Text>
        <View style={styles.subSectionHeaderLine} />
      </View>

      <View>
        {fields.map(({ id, ...row }) => (
          <View key={id} style={[styles.flexRow, styles.trBorderBottom]}>
            <View style={[styles.flex1, styles.padding8, styles.flexRow]}>
              {row.indent && <View style={{ paddingLeft: 10 }} />}
              <Text style={row.isTotal ? styles.thText : styles.tdText}>
                {row.name}
              </Text>
            </View>
            <View
              style={[
                styles.flex1,
                styles.justifyEnd,
                styles.flexRow,
                styles.paddingTop8,
                styles.paddingBottom8,
              ]}
            >
              <Text style={row.isTotal ? styles.thText : styles.tdText}>
                {moneyFormatter.format(row.value)}
              </Text>
            </View>
          </View>
        ))}
      </View>
    </>
  );
}

export default WrkCmpTotals;

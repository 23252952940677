import React from "react";
import isPropsEqual from "react-fast-compare";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableCellComponent, { TableCellProps } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Box from "@mui/material/Box";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useNumberFormatter } from "../../hooks";

class TableCell extends React.Component<TableCellProps> {
  shouldComponentUpdate(nextProps: TableCellProps) {
    const { children: nextChildren } = nextProps; // eslint-disable-line
    const { children: thisChildren } = this.props; // eslint-disable-line

    if (typeof thisChildren === "string") return nextChildren !== thisChildren;
    if (
      typeof thisChildren === "object" &&
      (thisChildren as any).type === "span"
    ) {
      const { props: nextProps } = nextChildren as any;
      const { props: thisProps } = thisChildren as any;
      return nextProps.children !== thisProps.children; // eslint-disable-line
    }

    return true;
  }

  render() {
    return <TableCellComponent {...this.props} />;
  }
}

interface Props {
  minPremium: number;
  totalCalculations: {
    sumInsured: number;
    sumInsuredPremium: number;
    discounts: number;
    optionalExtras: number;
    subTotal: number;
    insuranceCommLevy: number;
    gst: number;
    periodTotal: number;
  }[];
  grandTotal: number;
  onSetYears: (years: number) => void;
  yearsRequired: number;
}

function Calculations({
  minPremium,
  totalCalculations,
  grandTotal,
  onSetYears,
  yearsRequired,
}: Props) {
  const handleChangeYear = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    onSetYears(parseInt(value, 10));
  };

  const moneyFormatter = useNumberFormatter();

  const _calculations = [
    {
      id: "sumInsured",
      name: "Sum Insured",
      value: 0,
    },
    {
      id: "sumInsuredPremium",
      name: "Sum Insured Premium",
      value: 0,
    },
    {
      id: "discounts",
      name: "Less Discounts",
      value: 0,
      indent: true,
    },
    {
      id: "optionalExtras",
      name: "Plus Optional Extras",
      value: 0,
      indent: true,
    },
    {
      id: "subTotal",
      name: `Sub Total (Minimum premium is ${moneyFormatter.format(
        minPremium
      )})`,
      value: 0,
      indent: true,
    },
    {
      id: "insuranceCommLevy",
      name: "Insurance Comm Levy",
      value: 0,
    },
    {
      id: "gst",
      name: "GST",
      value: 0,
    },
  ];

  const totals = [
    {
      id: "periodTotal",
      name: "Period Total",
      value: 0,
    },
    {
      id: 8,
      name: "Grand Total",
      value: grandTotal,
      isTotal: true,
    },
  ];

  const calculations =
    yearsRequired > 1
      ? [..._calculations, ...totals]
      : [..._calculations, totals[1]];

  return (
    <>
      <Box mt={2} mb={1}>
        <FormControl component="fieldset">
          <FormLabel component="legend" sx={{ fontSize: 14 }}>
            Number of Years
          </FormLabel>
          <RadioGroup
            row
            aria-label="Number of Years"
            name="years"
            defaultValue="end"
            value={yearsRequired}
            onChange={handleChangeYear}
          >
            {[1, 2, 3, 4, 5].map((year, count) => (
              <FormControlLabel
                key={count}
                value={year}
                control={<Radio color="primary" />}
                label={`${year} Year${count === 0 ? "" : "s"}`}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </Box>
      <TableContainer>
        <Table aria-label="calculations table">
          <TableHead>
            <TableRow>
              <TableCell>&nbsp;</TableCell>
              {yearsRequired > 1 &&
                [...Array(yearsRequired).keys()].map((year, yearCount) => (
                  <TableCell key={yearCount} align="right">
                    Year {year + 1}
                  </TableCell>
                ))}
              {yearsRequired === 1 && (
                <TableCell align="right">Single Year</TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {calculations.map(({ id, ...row }) => (
              <TableRow
                key={id}
                sx={{
                  ...((row as any).isTotal && {
                    backgroundColor: "#e2e2e2",
                    borderTop: "2px solid #222",
                  }),
                }}
              >
                <TableCell component="th" scope="row">
                  <Box
                    component="span"
                    sx={{
                      ...((row as any).indent && {
                        display: "block",
                        pl: 2,
                      }),
                      ...((row as any).isTotal && {
                        fontWeight: "bold",
                        fontSize: "1.1em",
                      }),
                    }}
                  >
                    {row.name}
                  </Box>
                </TableCell>
                {!(row as any).isTotal &&
                  [...Array(yearsRequired).keys()].map((_, yearIndex) => {
                    const tCalculations: any = totalCalculations[yearIndex];
                    return (
                      <TableCell key={yearIndex} align="right">
                        {moneyFormatter.format(
                          tCalculations ? tCalculations[id] : 0
                        )}
                      </TableCell>
                    );
                  })}
                {(row as any).isTotal && (
                  <TableCell
                    colSpan={6}
                    align="right"
                    sx={{
                      fontWeight: "bold",
                      fontSize: "1.1em",
                    }}
                  >
                    {moneyFormatter.format(row.value)}
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default React.memo(Calculations, isPropsEqual);

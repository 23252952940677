import {
  CalculationsFunctionsType,
  QuoteFormData,
  QuoteFormState,
  TaxesType,
} from "../types";

export const calculatePremium = ({
  sumInsured,
  quoteData,
}: {
  sumInsured: number;
  quoteData: QuoteFormData;
}) => {
  if (!sumInsured) return 0;
  if (!quoteData) return 0;

  const { sumInsuredPremiumRates } = quoteData;

  const index = sumInsuredPremiumRates.length - 1;

  if (sumInsured >= sumInsuredPremiumRates[index].minAmount)
    return sumInsured * (sumInsuredPremiumRates[index].rate / 100);

  const premiumRate = sumInsuredPremiumRates.find((band) => {
    const { minAmount, maxAmount } = band;
    return sumInsured >= minAmount && sumInsured <= maxAmount;
  });

  if (!premiumRate) return 0;

  return Math.ceil(sumInsured * (premiumRate.rate / 100));
};

export const calculateTppdLimitIncreaseAmount = ({
  premium,
  quoteData,
  insuranceValues,
}: {
  premium: number;
  quoteData: QuoteFormData;
  insuranceValues: QuoteFormState["insuranceValues"];
}) => {
  if (!quoteData) return 0;
  if (!premium) return 0;
  if (premium && isNaN(Number(premium))) return 0;

  const { tppdLimitInc } = insuranceValues;
  const { optionalExtras } = quoteData;

  const optExtData = optionalExtras.find((ex) => ex.title === "TPPD Increase");

  if (!optExtData) return 0;

  const optExtDiscount = optExtData.data.find(
    (e) =>
      parseFloat(e.option) === parseFloat(tppdLimitInc as unknown as string)
  );

  if (!optExtDiscount) return 0;

  const { discountPercentage } = optExtDiscount;

  return Math.ceil(premium * (discountPercentage / 100));
};

export const getTotalsCalculations = ({
  sumInsuredPremium,
  yearsRequired,
  sumInsured,
  calculationFunctions,
  discountPercentage,
  minPremium,
  taxes,
}: {
  sumInsuredPremium: number;
  yearsRequired: number;
  sumInsured: number;
  discountPercentage: number;
  minPremium: number;
  taxes: TaxesType;
  calculationFunctions: CalculationsFunctionsType;
}) => {
  const years =
    sumInsuredPremium > 0
      ? [1, 0.82, 0.64, 0.46, 0.28].slice(0, yearsRequired)
      : [];

  let grandTotal = 0;
  const totalCalculations = years.map((multiplyer) => {
    const sInsured = Math.round(sumInsured * multiplyer);
    const sInsuredPremium = calculationFunctions.calculatePremium(sInsured);
    const dCounts = Math.round((sInsuredPremium * discountPercentage) / 100);
    const oExtras = Math.round(
      calculationFunctions.calculateExtras(sInsuredPremium)
    );
    const _sTotal = sInsuredPremium - dCounts + oExtras;
    const sTotal = Math.max(minPremium, _sTotal);
    const icl = Math.round((sTotal * taxes.icl) / 100);
    const gst = Math.round((sTotal * taxes.gst) / 100);
    const periodTotal = sTotal + icl + gst;
    grandTotal += periodTotal;
    return {
      sumInsured: sInsured,
      sumInsuredPremium: sInsuredPremium,
      discounts: dCounts,
      optionalExtras: oExtras,
      subTotal: sTotal,
      insuranceCommLevy: icl,
      gst,
      periodTotal,
    };
  });

  return {
    grandTotal,
    totalCalculations,
  };
};
